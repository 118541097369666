input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.changeLableStyle label{
  width: max-content;
}
.changeLableStyle{
  justify-content: center
}

