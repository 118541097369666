.workplan-box {
  background-color: white;
  padding: 10px 30px;
  margin: 20px 10px;
  border-radius: 8px;
}
.MuiOutlinedInput-input {
  height: 15px !important;
  width: 90%;
}
.MuiFormControl-root {
  width: 90%;
}
#center-center {
  height: 180px;
}
#MuiGrid-root {
  height: 180px !important;
}
.makeStyles-container-163 {
  margin-top: 16px;
}
.Component-root-164 {
  padding-bottom: 8px;
  height: 200px !important;
}
.rdt {
  padding: 5px;
  width: 91%;
}
.rdt .form-control {
  font-size: 14px !important;
}
.rdt input[type="text"]:first:disabled {
  background-color: aqua !important;
}
.rdt input[type="text"]:disabled {
  background: #dddddd !important;
}
.MuiInputBase-root .MuiInputBase-input {
  height: 14px;
}
.info-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-top: 12px;
  margin-left: 12px;
}
.MuiFormLabel-root.Mui-focused {
  background: white !important;
}
#outlined-basic:focus {
  border: none !important;
}
input[type="number"] {
  visibility: visible;
}
.checkbox {
  color: black;
  font-size: 14px;
  margin-left: 20px;
}
.none {
  color: red;
}
input[type="number"] {
  padding: 3px;
}
.indicatorError {
  border: 2px solid red;
}
#rate-box {
  border: 2px solid #646464;
  background-color: #959595;
  width: 38px;
  height: 35px;
}
#remediationeffortpercent {
  margin-top: 20px;
}
#workerreportedissuespercent {
  margin-top: 20px;
}
.percent-box input[type="number"]::-webkit-inner-spin-button,
.percent-box input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.number-box input::-webkit-outer-spin-button,
.number-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.status-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: green;
  color: rgb(255, 255, 109);
}
.pie-chart {
  margin-bottom: 100px;
  height: 55%;
  width: 55%;
}
.swal-footer {
  text-align: center;
  padding: 10px;
  margin: 0px;
}
#congratulations-box {
  border: 1px solid black;
  height: 200px;
  height: 200px;
  margin: 8% 20%;
  padding: 20px 30px;
  text-align: "center";
}
@media only screen and (max-width: 600px) {
  #congratulations-text {
    font-weight: "500";
    font-size: "16px";
  }
}
.infor {
  width: 10px;
  height: 10px;
  padding: 0 3px;
  margin: 0 5px;
  font-size: 7px;
}
.states {
  font-size: 11px;
}
.indicators {
  text-align: center;
  margin-top: 37%;
}
.indicators1 {
  text-align: center;
}
.remidation-outcome {
  margin-top: -48%;
  text-align: center;
  font-size: 10px;
}
.color-info-r {
  background-color: #148696;
}
.color-info-u {
  background-color: gray;
}
.color-info-p {
  background-color: #a3199c;
}
.color-info-f {
  background-color: #fdc161;
}
.color-info-g {
  background-color: #a6dfdd;
}
.color-info-e {
  background-color: #148696;
}
.trash-btn-hide {
  margin-left: -1%;
  visibility: hidden;
  cursor: pointer;
  color: rgb(183, 183, 183);
}
.trash-btn-show {
  margin-left: -1%;
  visibility: show;
  cursor: pointer;
  color: rgb(183, 183, 183);
}
.speedo-container {
  padding: 0;
}
.speedo-container .MuiGrid-root {
  margin: 0;
}
.speedometer {
  margin-bottom: -35px;
}
.activity_area_text {
  margin-top: 20px;
  margin-left: 15px;
  font-weight: 500;
  font-size: 16.2px;
}

.activity_summary_label {
  font-size: 14.2px;
  margin-left: 3px;
}
.training_header {
  font-weight: 500;
  margin-left: -10px;
}
.training_text {
  display: inline-block;
}
.activity_summary_sublabel {
  margin-left: 33px;
  display: inline-block;
}
.activity_summary_date {
  justify-content: center;
}
.activity_paper {
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 1px 1px rgb(0, 0, 0, 0, 3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
@media screen and (max-width: 1363px) {
  .speedometer {
    margin-right: -10;
  }
}
@media screen and (max-width: 1275px) {
  .speedo-container .MuiGrid-root {
    margin: 10px;
  }
  .speedometer {
    margin-right: -10;
  }
}
