.activity_area_text {
  margin-top: 20px;
  margin-left: 15px;
  font-weight: 700;
  font-size: 16.2px;
}

.activity_summary_label {
  font-size: 14.2px;
  margin-left: 3px;
}

.activity_summary_category {
  font-weight: 500;
  font-size: 15.2px;
  margin-left: 3px;
}

.activity_summary_sublabel {
  margin-left: 33px;
  display: inline-block;
}

.activity_summary_date {
  justify-content: center;
}

.activity_summary_recruitment_fees {
  margin-left: 30px;
}

.activity_paper {
  padding: 1rem;
  margin: 1rem 0;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.activity_container {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin-top: 30px;
  padding-bottom: 15px;
  font-size: 16px;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}
