.select-container {
  position: relative; /* Adjust as needed */
}

.floating-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
  color: #9e9e9e;
  font-size: 16px;
  pointer-events: none;
}

.floating-label.focused {
  top: -10px;
  font-size: 12px;
  color: black;
  background-color: white;
  padding: 0 4px;
}

.floating-label.unfocused {
  top: -10px;
  font-size: 12px;
  color: gray;
  background-color: white;
  padding: 0 4px;
}
