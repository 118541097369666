.answer_checkbox {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.87);
}

.question {
  padding-top: 15px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  text-align: justify;
}

.tooltip {
  font-size: 0.85rem;
}

.sub_text {
  font-size: 0.85rem;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 10px;
}

.next_question {
  margin-top: 15px;
}

.text_headers {
  font-style: italic;
  font-weight: 500;
}

.intro_text {
  margin: 0;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.87);
  text-align: justify;
}

.old-notes-text {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.87);
}

.prev_data {
  margin-top: 10px;
}
